import { Entry } from '@/@types/entries';
import { Header, Separator } from '@/components/common/PosPrint';
import { PrintInfoSimple } from '@/components/common/PosPrint/PrintInfoSimple';
import React from 'react';

interface IEntryPrintProps {
  entry?: Entry;
  containerRef: any | null;
}

export function EntryPrintSimple({ entry, containerRef }: IEntryPrintProps) {
  return (
    <div
      ref={containerRef}
      style={{
        printColorAdjust: 'economy',
        width: '7.2cm',
        color: 'black',
        padding: '0.25cm',
        fontFamily: 'Arial',
        fontSize: '11px',
      }}
    >
      <Separator />
      <Header />
      <Header
        title={`${entry?.operation?.number || ''} - ${
          entry?.operation?.name || ''
        }`}
      />
      <Separator />
      <PrintInfoSimple
        number={entry?.number}
        date={entry?.issueDate}
        staff={entry?.staff}
        note={entry?.note}
      />
      <Separator />
      <div style={{ fontWeight: 'bold' }}>PRODUTOS</div>
      <Separator />
      <table
        style={{
          width: '100%',
          borderCollapse: 'collapse',
        }}
      >
        <tbody>
          {entry?.products?.map((product, index) => (
            <tr
              key={product.id}
              style={{
                borderBottom:
                  index + 1 < entry?.products?.length
                    ? '1px solid #333333'
                    : '',
              }}
            >
              <td>{product.entryProduct.name}</td>
              <td
                style={{
                  textAlign: 'right',
                }}
              >
                {product.productQuantity.toFixed(2)}{' '}
                {product.entryUnit.abbreviation}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <Separator />
      <br />
      <div
        style={{ textAlign: 'center', marginTop: '16px', marginBottom: '24px' }}
      >
        <p>________________________________</p>
        <p>{entry?.user.name}</p>
      </div>
      <br />
    </div>
  );
}
